<template>
  <div>
    <v-container
      v-if="fetchingBusiness === false && fetchingWarehouses === false"
      id="dashboard"
      fluid
      tag="section"
    >
      <v-row>
        <template v-if="isAdministrable || isWarehouseManager">
          <!-- <v-row> -->
          <warehouse
            v-for="(warehouse, i) in getWarehouses"
            :key="i + 'w'"
            :warehouses="warehouse"
          />
          <br>
          <!-- </v-row> -->
        </template>
        <template
          v-if="isAdministrable || isStoreManager || isSeller"
        >
          <!-- <v-row> -->
          <business
            v-for="(item, i) in business"
            :key="i + 'b'"
            :business="item"
            :alt-role="altRole"
          />
          <!-- </v-row> -->
        </template>
      </v-row>
    </v-container>
    <dashboard-childs-skeleton v-else />
  </div>
</template>

<script>
  import { SUPER_ADMIN, GENERAL_MANAGER, ADMIN, STORE_MANAGER, WAREHOUSE_MANAGER, SELLER } from '@/const'
  import Business from '../components/dashboard/Business.vue'
  import Warehouse from '../components/dashboard/Warehouse.vue'
  import DashboardChildsSkeleton from './DashboardChildsSkeleton.vue'
  import { mapState, mapMutations, mapActions } from 'vuex'

  export default {
    name: 'DashboardChilds',

    components: {
      Business,
      Warehouse,
      DashboardChildsSkeleton,
    },

    data () {
      return {
        altRole: null,
      }
    },

    computed: {
      ...mapState(['user', 'childsBusiness', 'warehouses', 'fetchingBusiness', 'fetchingWarehouses']),

      isAdministrable () {
        return this.is(SUPER_ADMIN) || this.is(GENERAL_MANAGER) || this.is(ADMIN)
      },

      isStoreManager () {
        return this.is(STORE_MANAGER)
      },

      isWarehouseManager () {
        return this.is(WAREHOUSE_MANAGER)
      },

      isSeller () {
        return this.is(SELLER)
      },

      business () {
        if (this.isAdministrable) {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('business', this.childsBusiness)
          return this.childsBusiness.filter(item => item.status)
        } else {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('business', this.user?.authorization)
          return this.user?.authorization.filter(item => item.businessStatus)
        }
      },

      getWarehouses () {
        if (this.isAdministrable) {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('warehouses', this.warehouses)
          return this.warehouses.filter(item => item.status)
        } else {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('warehouses', this.user?.warehouses)
          return this.user?.warehouses.filter(item => item.warehouseStatus)
        }
      },

      // business () {
      //   // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length <= 0)
      //   // if (
      //   //   this.user?.authorization
      //   //     .filter(auth => auth.roleId === SUPER_ADMIN).length <= 0
      //   // ) {
      //   //   return this.user?.authorization
      //   // }

      //   // if (this.childsBusiness.length > 0) {
      //   //   return this.childsBusiness
      //   // }

      //   // this.fethBusinessParents()

      //   if (this.user?.authorization.filter(auth => auth.roleId === SUPER_ADMIN).length > 0) {
      //     this.changeAltRole('Super Administrador')

      //     return this.childsBusiness
      //   } else if (this.user?.authorization.filter(auth => auth.roleId === GENERAL_MANAGER).length > 0) {
      //     this.changeAltRole('Gerente General')

      //     return this.childsBusiness
      //   }
      //   return this.user?.authorization
      // },
      // warehouses () {
      //   return this.user.warehouses
      // },
    },

    watch: {
      warehouses (v) {
        // // // // // // // // // // // // // // // // // // // // // // // // // console.log(v)
      },
    },

    async created () {
      if (this.$route.params.id === 'undefined') {
        // Redirecting with a `window.location` because the vue-router library
        // has some issues that I can't resolve, if you are able at time to
        // try to solve this problem, comment the `window.location` and uncomment
        // the next line:
        //
        // this.$router.push('/close-session')
        window.location = '/close-session'
      }
      this.setActions(false)

      if (this.is(SUPER_ADMIN)) {
        this.altRole = 'Super Administrador'
      }

      if (this.is(GENERAL_MANAGER)) {
        this.altRole = 'Gerente General'
      }

      if (this.is(ADMIN)) {
        this.altRole = 'Administrador'
      }

      await this.fethBusinessChilds()
      await this.fethWarehouse()
      if (this.childsBusiness.length <= 0) {
        this.$router.push('/adminis/business_/' + this.$route.params.id)
      }
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('parent')
      this.setActionParentBusiness(Number(this.$route.params.id))
      this.assignAccess()
    },

    methods: {
      ...mapMutations({
        setActions: 'SET_ACTIONS_BUSINESS',
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActionParentBusiness: 'SET_ACTIONS_PARENT_BUSINESS',
        updateAccess: 'UPDATE_ACCESS',
      }),

      ...mapActions(['getChildsBusiness', 'getWarehousesByParentId']),

      assignAccess () {
        const res = []
        if (this.isAdministrable) {
          // // // console.log('ho')
          res.push(
            {
              title: 'Administración',
              icon: 'mdi-tune',
              group: 'admin',
              access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
              children: [
                {
                  icon: 'mdi-information-variant',
                  title: 'Informacion de Empresa',
                  to: `info-business/${Number(this.$route.params.id)}`,
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-auto-upload',
                  title: 'Carga masiva de productos',
                  to: 'products/bulk-load',
                  access: [SUPER_ADMIN],
                },
                {
                  icon: 'mdi-auto-upload',
                  title: 'Carga masiva de transacciones',
                  to: 'transactions/bulk-load',
                  access: [SUPER_ADMIN],
                },
                {
                  icon: 'mdi-account-cog-outline',
                  title: 'Usuarios',
                  to: 'users',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-clipboard-account-outline',
                  title: 'Clientes',
                  to: 'clients',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-account-tie-outline',
                  title: 'Proveedores',
                  to: 'providers',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-cash',
                  title: 'Monedas',
                  to: 'currencies',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-store',
                  title: 'Tiendas',
                  to: 'store',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-view-comfy',
                  title: 'Tipos de almacen',
                  to: 'warehouses_types',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-warehouse',
                  title: 'Almacenes',
                  to: 'warehouses',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-chart-timeline-variant',
                  title: 'Linea de productos',
                  to: 'product-line',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-graph',
                  title: 'Clasificacion de productos',
                  to: 'product-classification',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-file-tree-outline',
                  title: 'Categorias de productos',
                  to: 'product-category',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-sitemap',
                  title: 'Tipos de productos',
                  to: 'product-type',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-basket',
                  title: 'Productos',
                  to: 'products',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
                {
                  icon: 'mdi-filter-menu-outline',
                  title: 'Reporte avanzado',
                  to: 'reports',
                  access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER],
                },
              ],
            },
          )
        }

        if (this.isAdministrable || this.isWarehouseManager) {
          // // // console.log(this.getWarehouses)
          this.getWarehouses.forEach(item => {
            res.push(
              {
                title: item.name,
                icon: 'mdi-warehouse',
                group: 'admini',
                access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                children: this.isAdministrable ? [
                  {
                    icon: 'mdi-view-dashboard',
                    title: 'Dashboard',
                    to: `warehouses/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                  {
                    icon: 'mdi-arrow-down-bold',
                    title: 'Entradas',
                    to: `warehouse/inputs/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                  {
                    icon: 'mdi-arrow-up-bold',
                    title: 'Salidas',
                    to: `warehouse/outputs/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                  // {
                  //   icon: 'mdi-certificate-outline',
                  //   title: 'Despachos',
                  //   to: `warehouse/concessions/${item.id}`,
                  //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  // },
                  // {
                  //   icon: 'mdi-archive-arrow-down-outline',
                  //   title: 'Devolución Despachos',
                  //   to: `warehouse/returns/${item.id}`,
                  //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  // },
                  {
                    icon: 'mdi-newspaper-variant',
                    title: 'Reportes',
                    to: `warehouse/reports/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                ] : [
                  {
                    icon: 'mdi-view-dashboard',
                    title: 'Dashboard',
                    to: `warehouses/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                  {
                    icon: 'mdi-arrow-down-bold',
                    title: 'Entradas',
                    to: `warehouse/inputs/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                  {
                    icon: 'mdi-arrow-up-bold',
                    title: 'Salidas',
                    to: `warehouse/outputs/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                  // {
                  //   icon: 'mdi-archive-arrow-down-outline',
                  //   title: 'Devolución Despachos',
                  //   to: `warehouse/returns/${item.id}`,
                  //   access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  // },
                  {
                    icon: 'mdi-newspaper-variant',
                    title: 'Reportes',
                    to: `warehouse/reports/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                ],
              })
          })
        }

        if (this.isAdministrable || this.isStoreManager || this.isSeller) {
          this.business.forEach(item => {
            // // // console.log(item)
            res.push(
              {
                title: item.name || item.business,
                icon: 'mdi-store',
                group: 'adminis',
                access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, SELLER],
                children: this.isAdministrable || this.isStoreManager ? [
                  {
                    icon: 'mdi-view-dashboard',
                    title: 'Dashboard',
                    to: `business_/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                  {
                    icon: 'mdi-currency-usd',
                    title: 'Presupuestos',
                    to: `business/new-sale/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, SELLER],
                  },
                  {
                    icon: 'mdi-archive-arrow-down-outline',
                    title: 'Devoluciones',
                    to: `business/returns/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER],
                  },
                  {
                    icon: 'mdi-newspaper-variant',
                    title: 'Reportes',
                    to: `business/reports/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER],
                  },
                  {
                    icon: 'mdi-cloud-upload-outline',
                    title: 'Carga masiva de transacciones para tienda',
                    to: `transactions/store/bulk-load/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN],
                  },
                ] : [
                  {
                    icon: 'mdi-view-dashboard',
                    title: 'Dashboard',
                    to: `business_/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, WAREHOUSE_MANAGER],
                  },
                  {
                    icon: 'mdi-currency-usd',
                    title: 'Presupuestos',
                    to: `business/new-sale/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER, SELLER],
                  },
                  {
                    icon: 'mdi-newspaper-variant',
                    title: 'Reportes',
                    to: `business/reports/${item.id}`,
                    access: [SUPER_ADMIN, ADMIN, GENERAL_MANAGER, STORE_MANAGER],
                  },
                ],
              },
            )
          })
        }
        this.updateAccess(res)
      },

      is (rol) {
        return this.user?.authorization.filter(auth => auth.roleId === rol).length > 0 || false
      },

      async fethBusinessChilds () {
        await this.getChildsBusiness({ id: this.$route.params.id })
      },

      async fethWarehouse () {
        await this.getWarehousesByParentId({ id: this.$route.params.id })
      },

      changeAltRole (v) {
        this.altRole = v
      },
    },
  }
</script>
