<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(item, index) in [1, 2, 3]"
        :key="index"
        cols="12"
        sm="6"
        lg="4"
      >
        <v-skeleton-loader
          v-bind="attrs"
          type="image"
          max-height="110px"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardChildsSkeleton',
    data () {
      return {
        attrs: {
          class: 'mb-1',
          elevation: 6,
        },
      }
    },
  }
</script>
