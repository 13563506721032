<template>
  <v-col
    cols="12"
    sm="6"
    md="3"
    lg="3"
  >
    <!--
      :top="(warehouses.warehouseName || warehouses.name)"
      :buttom="(warehouses.warehouseName || warehouses.name)" -->
    <base-material-stats-card
      class="toggle-item"
      color="egrey"
      icon="mdi-warehouse"
      :title="'Almacen tipo ' + warehouses.warehouseType"
      :top="top"
      :buttom="buttom"
      :to="`/admini/warehouses/${warehouses.warehouseId}`"
    />
  </v-col>
</template>

<script>
  export default {
    name: 'Warehouse',

    props: {
      warehouses: {
        type: Object,
        default: () => {},
      },
    },

    computed: {
      top () {
        const text = this.warehouses.warehouseName || this.warehouses.name
        const top = text.split('-')[0]

        return top
      },
      buttom () {
        const text = this.warehouses.warehouseName || this.warehouses.name
        const buttom = text.split('-')[1]

        return buttom
      },
    },
  }
</script>

<style scoped>
.toggle-item {
  transform: scale(1);
  transition: 200ms all ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select:none;
}

.toggle-item:hover {
  transform: scale(1.05);
}

.toggle-item:active {
  transform: scale(1);
}
</style>
